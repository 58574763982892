<template>
    <v-card :loading="loading" width="100%">
        <v-card-title class="text-h5">
            <v-btn
                small
                icon
                @click="closeDatesForm"
                color="primary"
                class="mr-2"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>
                {{ this.title }}
            </h3></v-card-title
        >
        <v-divider class="ml-4 mr-5" />
        <!-- Set Promise Date -->
        <v-card-text class="pt-4">
            <v-form>
                <v-row no-gutters>
                    <v-col cols="12" class="pt-4">
                        <v-menu
                            ref="menu"
                            :close-on-content-click="true"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    class="py-0 my-0"
                                    v-model="selectedDate"
                                    color="primary"
                                    label="Date"
                                    readonly
                                    flat
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    prepend-icon="mdi-calendar-blank-outline"
                                ></v-combobox>
                            </template>
                            <v-date-picker
                                v-model="selectedDate"
                                scrollable
                                :min="minDate"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!selectedDate || selectedDate == date"
                @click="saveQuoteDates"
                color="primary"
                :loading="loading"
                rounded
            >
                UPDATE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
export default {
    name: 'SetQuoteDates',
    props: {
        title: { type: String },
        date: { type: String },
        minDate: { type: String },
        quoteId: { type: String },
        quote: {type: Array},
    },
    data() {
        return {
            selectedDate: null,
            invitationDate: undefined,
            requestDate: undefined,
            promiseDate: undefined,
            loading: false,
        }
    },
    mounted() {
        this.selectedDate = this.date ? this.date : null
    },
    inject: {
        replaceQuote: {
            from: 'replaceQuote',
            default: () => null,
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDatesForm() {
            this.$emit('closeQuoteDatesForm')
        },
        async saveQuoteDates() {
            try {
                this.loading = true

                if (this.title == 'SET INVITATION DATE') {
                    this.invitationDate = this.selectedDate
                }
                if (this.title == 'SET DUE DATE') {
                    this.requestDate = this.selectedDate
                }
                if (this.title == 'SET PROMISE DATE') {
                    this.promiseDate = this.selectedDate
                }
                const response = await API.setQuoteDates({
                    quoteId: this.quoteId,
                    invitationDate: this.invitationDate,
                    requestDate: this.requestDate,
                    promiseDate: this.promiseDate,
                })

                this.$emit('updateDates', {
                    title: this.title,
                    date: response,
                })
                this.selectedDate = ''
                this.invitationDate = undefined
                this.requestDate = undefined
                this.promiseDate = undefined
                
                if (this.$router.currentRoute.name === 'estimating-plan') {
                    this.replaceQuote(this.quote.status, this.quote)
                }

                this.closeDatesForm()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
